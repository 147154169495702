import { createTheme } from '@mui/material/styles';
//import { frFR } from '@mui/x-date-pickers/locales';

const theme = createTheme({
  palette: {
    primary: {
      main: '#006176',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#CDBC0D',
      contrastText: 'white',
    },
    thomas: {
      main: '#158BDA',
      contrastText: '#FFF',
    },
    amandine: {
      main: '#07CA56',
      contrastText: '#FFF',
    },
    georges: {
      main: '#CE0000',
      contrastText: '#FFF',
    },
    valerie: {
      main: '#F355D4',
      contrastText: '#FFF',
    },
    mathieu: {
      main: '#CE7F00',
      contrastText: '#FFF',
    },
    delete: {
      main: '#F00',
      contrastText: '#FFF',
    }
  },
  appBar: {
    height: '42px'
  },
  card: {
    defaultPadding: 5
  }
}
//,frFR
);

export default theme;