// #region imports
import { React, useState, useEffect } from 'react'
import axios from 'axios';
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  useNavigate
} from "react-router-dom";
import {
  Snackbar,
  Alert,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Chip,
  Card,
  LinearProgress,
  IconButton
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import SyncIcon from '@mui/icons-material/Sync';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';
import Calendar from 'react-calendar';

// #endregion

// #region Style

import { styled } from '@mui/system';
import theme from './style/mainTheme';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Appbar = styled('div')({
  top: '0px',
  zIndex: 1000,
  position: 'fixed',
  width: "100%",
  height: theme.appBar.height,
  backgroundColor: theme.palette.primary.main
});

const CardCustom = styled(Card)({
  backgroundColor: '#eee',
  boxShadow: 'none',
  padding: '10px',
  borderRadius: 5
});

const CalendarCustom = styled(Calendar)({
  margin: "auto",
  marginTop: "20px",
  backgroundColor: "transparent",
  padding: "10px",
  borderRadius: "3px",
  ".react-calendar__navigation": {
    display: "flex",
    ".react-calendar__navigation__label": { fontWeight: "bold" },
    ".react-calendar__navigation__arrow": { flexGrow: 0.333 }
  },
  ".react-calendar__month-view__weekdays": { textAlign: "center" },
  button: {
    margin: "3px",
    backgroundColor: "#3B314F",
    border: "0",
    borderRadius: "3px",
    color: "white",
    padding: "5px 0px",
    "&:hover": { backgroundColor: "brown" },
    "&:active": { backgroundColor: "#f00" }
  },
  ".react-calendar__tile--now": {
    border: '2px solid #3B314F',
    backgroundColor: '#836BB2',
    fontWeight: 'bold',
    color: 'orange'
  },
  ".react-calendar__month-view__days": {
    display: "grid !important",
    gridTemplateColumns: "14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%",
    ".react-calendar__tile":
    {
      width: '42px',
      height: '42px'
    },
    ".react-calendar__tile--range": { boxShadow: "0 0 6px 2px red" }
  },
  ".react-calendar__month-view__days__day--neighboringMonth": { opacity: 0.7 },
  ".react-calendar__month-view__days__day--weekend": { color: "#dfdfdf" },
  ".react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view__decades": {
    display: "grid !important",
    gridTemplateColumns: "20% 20% 20% 20% 20%",
    "&.react-calendar__year-view__months": {
      gridTemplateColumns: "33.3% 33.3% 33.3%"
    },
    ".react-calendar__tile":
    {
      maxWidth: "initial !important"
    }
  }
})

const GridRow = styled(Grid)({
  padding: theme.card.defaultPadding + "px",
  [theme.breakpoints.down('md')]: {
    padding: theme.card.defaultPadding + "px 0px"
  },
});

// #endregion

// #region Const

const fixedUsers = [
  {
    user: "thomas",
    active: false
  },
  {
    user: "amandine",
    active: false
  },
  {
    user: "georges",
    active: false
  },
  {
    user: "valerie",
    active: false
  },
  {
    user: "mathieu",
    active: false
  }
]

const apiUrl = window.apiUrl
const SSOUrl = window.SSOUrl
const SSOApiUrl = window.SSOApiUrl
const applicationName = "Scheduler"

// #endregion

// #region App

export default function App() {

  const [status, setStatus] = useState({})
  const [snackbar, setSnackbar] = useState(false);
  function updateStatus(severity, message) {
    setStatus({ severity, message })
    setSnackbar(true)
  }

  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)

  const [loading, setLoading] = useState(null)

  if (!token && !window.location.pathname.includes("Token/")) {
    window.location.href = SSOUrl
  }

  useEffect(() => {
    if (token) {
      axios.get(SSOApiUrl + "Authenticate?token=" + token + "&application=" + applicationName)
        .then(input => {
          if (input.data.valid) {
            setUser(input.data.login)
            updateStatus("info", "Bienvenue " + input.data.login)
          } else {
            updateStatus("error", "La validation de l'utilisateur à échouée")
          }
        })
        .catch(() => updateStatus("error", "Une erreur est survenue lors de la récupération de l'utilisateur"))
    }
  }, [token]);

  // #region http handler

  async function httpcall(type, url, payload, callback) {
    var content;
    setLoading(true)
    try {
      switch (type) {
        case "get": {
          content = await axios.get(apiUrl + url, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback(content.data)
          break
        }
        case "post": {
          content = await axios.post(apiUrl + url, payload ? payload : null, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
        case "update": {
          content = await axios.update(apiUrl + url, payload ? payload : null, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
        case "delete": {
          content = await axios.delete(apiUrl + url, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
      }
      setLoading(false)
    }
    catch (exception) {
      setLoading(false)
      if (exception.status && exception.status === 401) {
        window.location.href = SSOUrl
      } else if (exception.status && exception.status === 403) {
        updateStatus("warning", "This action is forbiden")
      } else {
        updateStatus("error", "request failed (" + exception.status + ")")
      }
    }
  }

  // #endregion

  return (<>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <>
          <Appbar>
            {loading ?
              <LinearProgress color="secondary" sx={{ width: '100%', position: 'relative', bottom: '0px' }} />
              : <></>}
            <Grid container spacing={0} alignItems='center' justifyContent='space-between' style={{ height: '100%' }}>
              <Grid item>
                <Grid container alignItems='center' >
                  <CalendarMonthIcon style={{ color: 'white', margin: '0px 5px' }} />
                  <div style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px' }}>
                    Calendrier
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Appbar>
          <Grid style={{ marginTop: '42px' }}>
            <Routes>
              <Route path="/Token/:token" element={<TokenHandler setter={setToken} />} />
              {user != null ?
                <>
                  <Route path="/" element={<Home statusUpdate={updateStatus} httpcall={httpcall} user={user} />} />
                  <Route path="*" element={<Home statusUpdate={updateStatus} httpcall={httpcall} user={user} />} />
                </>
                : <></>}
            </Routes>
          </Grid>
        </>
      </ThemeProvider>
      <Snackbar
        open={snackbar}
        autoHideDuration={2500}
        onClose={() => { setSnackbar(false) }}
        anchorOrigin={{ vertical: useMediaQuery(theme.breakpoints.up("md")) ? 'top' : 'bottom', horizontal: 'center' }}>
        <Alert severity={status.severity ? status.severity : "error"} variant="filled">
          {status.message ? status.message : "error, no data transmited to this box"}
        </Alert>
      </Snackbar>
    </BrowserRouter>
  </>)
}

// #endregion

const Home = (props) => {

  const [events, setEvents] = useState(null)
  const [users, setUsers] = useState(fixedUsers)

  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [selectedDateEvents, setSelectedDateEvents] = useState(null)

  const [filteredEvents, setFilteredEvents] = useState(null)

  const [newEventTitle, setNewEventTitle] = useState("")
  const [newEventDescription, setNewEventDescription] = useState("")
  const [newEventDate, setNewEventDate] = useState(dayjs())
  const [newEventPrivate, setNewEventPrivate] = useState(false)

  useEffect(() => {
    let temp = [...users]
    temp[temp.findIndex(u => u.user === props.user)].active = true
    setUsers(temp)

    props.httpcall("get", "get?year=" + selectedDate.year().toString() + "&month=" + (selectedDate.month() + 1).toString(), null, input => setEvents(input))
  }, []);

  function updateUserStatus(index) {
    let temp = [...users]
    temp[index].active = !temp[index].active
    setUsers(temp)
  }

  function refresh() {
    props.httpcall("get", "get?year=" + selectedDate.year().toString() + "&month=" + (selectedDate.month() + 1).toString(), null, input => setEvents(input))
  }

  function handleDateChange(date) {
    setSelectedDate(dayjs(date))
    if (filteredEvents != null && filteredEvents.length > 0) {
      setSelectedDateEvents(filteredEvents.filter(event => dayjs(event.date).isSame(date, 'day')))
    }
  }

  function handleMonthChange({ activeStartDate, view }) {
    if (view === "month") {
      setSelectedDate(dayjs(activeStartDate))
      setSelectedDateEvents(events.filter(event => dayjs(event.date).isSame(activeStartDate, 'day')))
      props.httpcall("get", "get?year=" + dayjs(activeStartDate).year().toString() + "&month=" + (dayjs(activeStartDate).month() + 1).toString(), null, input => setEvents(input))
    }
  }

  useEffect(() => {
    if (events != null && events.length > 0) {
      setFilteredEvents(events.filter(ev => users.find(u => u.user === ev.author).active))
      setSelectedDateEvents(events.filter(ev => users.find(u => u.user === ev.author).active).filter(event => dayjs(event.date).isSame(selectedDate, 'day')))
    } else if (events != null && events.length === 0) {
      setFilteredEvents(null)
      setSelectedDateEvents(null)
    }
  }, [events]);

  useEffect(() => {
    if (events != null && events.length > 0) {
      setFilteredEvents(events.filter(ev => users.find(u => u.user === ev.author).active))
      setSelectedDateEvents(events.filter(ev => users.find(u => u.user === ev.author).active).filter(event => dayjs(event.date).isSame(selectedDate, 'day')))
    }
  }, [users]);

  function hourChange(date) {
    setNewEventDate(dayjs(date))
  }

  function handleCreation() {
    props.httpcall("post", "add", { id: "", title: newEventTitle, author: props.user, date: newEventDate.format(), private: newEventPrivate, description: newEventDescription }, callbackAdd)
  }

  function callbackAdd() {
    setNewEventTitle("")
    setNewEventDescription("")
    setNewEventDate(selectedDate ?? dayjs())
    setNewEventPrivate(false)
    props.statusUpdate("success", "Ok !")
    props.httpcall("get", "get?year=" + selectedDate.year().toString() + "&month=" + (selectedDate.month() + 1).toString(), null, input => setEvents(input))
  }

  function deleteEvent(event) {
    props.httpcall("post", "delete", event, () => props.httpcall("get", "get?year=" + selectedDate.year().toString() + "&month=" + (selectedDate.month() + 1).toString(), null, input => setEvents(input)))
  }

  return (
    <Grid container justifyContent="space-around">
      <GridRow item xs={12}>
        <CardCustom>
          <Grid container>
            {events != null ?
              <>
                {users.map((user, index) => (
                  <Grid key={user.user} item md={2} xs={4}>
                    <Button
                      variant={events.filter(h => h.author === user.user).length === 0 ? 'contained' : (user.active ? 'contained' : 'outlined')}
                      color={user.user}
                      disabled={events.filter(h => h.author === user.user).length === 0}
                      onClick={() => { updateUserStatus(index) }}
                      sx={{ marginBottom: '5px', zIndex: 100 }}
                    >
                      {user.user}
                    </Button>
                  </Grid>
                ))}
              </>
              : <></>}
          </Grid>
        </CardCustom>
      </GridRow>
      <GridRow item md={3} xs={12}>
        <CardCustom>
          <CalendarCustom
            onActiveStartDateChange={handleMonthChange}
            onChange={handleDateChange}
            tileContent={({ date, view }) => view === 'month'
              && filteredEvents != null
              && filteredEvents.find(ev => dayjs(date).isSame(ev.date, 'day'))
              != null ?
              DotComponent(filteredEvents.filter(ev => dayjs(date).isSame(ev.date, 'day')))
              : null} />
        </CardCustom>
      </GridRow>
      <GridRow item md={4} xs={12}>
        <CardCustom>
          {selectedDateEvents != null && selectedDateEvents.length !== 0 ?
            <>
              {selectedDateEvents.map(event => (
                <Grid key={event.id} container direction='column' sx={{ color: theme.palette[event.author].contrastText, backgroundColor: theme.palette[event.author].main, borderRadius: '10px', padding: '5px', margin: '5px 0px', position: 'relative' }}>
                  <Typography gutterBottom variant='h5'>
                    {event.title} - {event.author}
                  </Typography>
                  <Typography variant='h6'>
                    {dayjs(event.date).format('DD/MM/YY HH:mm')}
                  </Typography>
                  <Typography variant='body1'>
                    {event.description}
                  </Typography>
                  {event.author === props.user ?
                    <IconButton color='delete' sx={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => deleteEvent(event)}>
                      <DeleteIcon />
                    </IconButton>
                    : <></>}
                </Grid>
              ))}
            </>
            : <>Pas d'évènements</>}
        </CardCustom>
      </GridRow>
      <GridRow item md={5} xs={12}>
        <CardCustom>
          <Grid container direction='column'>
            <Typography>
              Ajouter évènement au {dayjs(selectedDate).format('DD/MM/YY')}
            </Typography>
            <Grid container alignItems='center'>
              <Checkbox value={newEventPrivate} onChange={(event) => setNewEventPrivate(event.target.checked)} />
              <Typography>Visible Pour moi uniquement</Typography>
            </Grid>
            <TextField label="Titre" value={newEventTitle} onChange={(event) => setNewEventTitle(event.target.value)} style={{ margin: '5px' }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker value={selectedDate ?? dayjs()} onAccept={hourChange} ampm={false} sx={{ margin: '5px' }} />
            </LocalizationProvider>
            <TextField label="Description (facultatif)" multiline rows={3} value={newEventDescription} onChange={(event) => setNewEventDescription(event.target.value)} style={{ margin: '5px' }} />
            <Button variant='contained' color='secondary' disabled={newEventTitle === "" || newEventDate == null} onClick={handleCreation}>
              valider
            </Button>
          </Grid>
        </CardCustom>
      </GridRow>
      <IconButton style={{ position: 'absolute', top: '1px', right: '1px', color: '#03fcf8', backgroundColor: '#03a5fc', zIndex: 2000 }}
        onClick={refresh}>
        <SyncIcon />
      </IconButton>
    </Grid>
  )
}

function DotComponent(events) {
  return <Grid container direction='column' sx={{ marginTop: '4px' }}>
    <Grid container justifyContent='space-around'>
      {fixedUsers.filter(u => events.find(ev => ev.author === u.user) != null).map(user => (
        <Chip key={user.user} color={user.user} style={{ height: '8px', width: '8px', margin: '1px' }} />
      ))}
    </Grid>
  </Grid>
}

//#region Token

const TokenHandler = (props) => {
  let { token } = useParams()
  const navigate = useNavigate()
  const [tempToken, setTempToken] = useState(null)

  useEffect(() => {
    if (tempToken == null) {
      setTempToken(token)
    } else {
      props.setter(tempToken)
      navigate("/")
    }
  }, [tempToken]);

  return (
    <></>
  )
}

//#endregion
